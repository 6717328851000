$primary: #25215e;
$secondary: #092745;
$Navyblue: #2240aa;
$darkblue: #0c1025;
$yellow: #f98c30;
$Poppins: "Poppins", sans-serif;
$SegoeUI: "Segoe UI, sans-serif";
$black: #262626;
$white: #fff;
$grey: #333333;
$mediumGray: #4f4f4f;
$lightGray: #a5a5a5;
$error: #f86c6b;
$border-light: #ece7e7;
$table-bg: #f2f2f2;
$red: #ff0707;
