@media screen and (max-width: 1700px) {
  .navbar.header_top::before {
    -webkit-clip-path: polygon(74% 0, 78% 100%, 0 100%, 0 0);
    clip-path: polygon(74% 0, 78% 100%, 0 100%, 0 0);
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .all-members-chat .btn-round {
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .navbar.header_top::before {
    -webkit-clip-path: polygon(68% 0, 72% 100%, 0 100%, 0 0);
    clip-path: polygon(68% 0, 72% 100%, 0 100%, 0 0);
  }
}

@media (max-width: 1400px) {
  .pgTitle {
    .container {
      text-align: center;
    }
  }
}

@media (min-width: 992px) {
  .roles-perm form [class*="col-"]:first-child {
    padding-left: 6px !important;
  }

  .card {
    form {
      [class*="col-"],
      [class*="col-"]:first-child,
      [class*="col-"]:last-child {
        padding-inline: 15px !important;
      }
      // [class*="col-"]:first-child{
      //     padding-left: 0px !important;
      // }
      // [class*="col-"]:last-child{
      //     padding-right: 0px;
      // }
    }
  }
}

@media (max-width: 992px) {
  .app {
    .container {
      max-width: 100% !important;
    }
  }
}

@media (min-width: 1024px) {
  .all-members-chat .chat-profile {
    .media-heading {
      width: 12ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .all-members-chat .btn-round {
    min-width: 110px;
  }
  .request-card-details {
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .box-container {
    width: 100% !important;
    h2 {
      font-size: 24px !important;
    }
  }
}

// ============================================
// responsive

@media screen and (max-width: 1598.98px) {
  .booking_card {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media screen and (max-width: 1598.98px) and (min-width: 1200px) {
  .booking_card {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .hide-1600 {
    display: none !important;
  }
}

@media screen and (max-width: 1199.98px) {
  .innerForm [class*="col-"]:first-child {
    border: none;
  }
  .calendar_box {
    margin-top: 30px !important;
  }
  .booking_card {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .login_wrapepr {
    .login_left {
      flex: 0 0 50%;
    }
  }

  .navbar.header_top {
    &::before {
      display: none;
    }
  }

  .header_top {
    .dropdown-menu {
      position: absolute !important;
      background-color: $white !important;
      border: initial;
    }
    .navbar-minimize button {
      color: $secondary !important;
    }
  }

  .btn-submit {
    padding-left: 20px;
    padding-right: 20px;
  }
  .login_wrapepr {
    .login_right {
      &.landing_buttons {
        .btn {
          margin: 10px !important;
        }
      }
      .socialLogin {
        button {
          padding: 10px 30px !important ;
          font-size: 13px;
        }
      }
    }
  }

  .nav-open .main-panel {
    transform: translate3d(0, 0, 0);
  }
  .main-panel {
    width: 100%;
  }

  .nav-open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .sidebarOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff00;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  html.nav-open .sidebarOverlay {
    display: block;
  }
  .sidebar {
    transform: translate3d(-280px, 0, 0);
    right: auto;
    left: 0 !important;
    .nav {
      .nav-item {
        .nav-link {
          margin: 10px 0px;
        }
      }
    }
  }

  .recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }

  .tab-content .capsule-container .capsule-btn {
    border: none !important;
    border-radius: 8px !important;
  }

  .all-members-chat-container {
    max-height: 350px;
    overflow-y: auto;
  }
  .mamber_header {
    i {
      display: block;
    }
  }
}

@media screen and (max-width: 991.98px) {
  .calendar_box {
    margin-top: 0px !important;
  }
  // .main-panel {
  //   width: 100%;
  // }
  // .sidebar {
  //   transform: translate3d(280px, 0, 0);
  // }
  // .nav-open .main-panel {
  //   transform: translate3d(0, 0, 0);
  // }
  ul.footer-menu {
    display: flex;
    justify-content: center;
    height: auto !important;
  }
  .footer:not(.footer-big) nav > ul li {
    margin: 0 !important;
  }
  .footer:not(.footer-big) nav > ul a {
    padding: 15px 10px !important;
    margin: 0;
  }
  p.copyright {
    margin: 0 !important;
    display: block !important;
    padding: 0 0 15px 0 !important;
  }
  .login_wrapepr {
    .login_left {
      display: none;
    }
    .login_right {
      overflow: visible;
    }
  }

  .navbar.header_top {
    &::before {
      display: none;
    }
  }
  .header_top {
    .dropdown-menu {
      position: absolute !important;
      background-color: $white !important;
      border: initial;
    }
    .navbar-minimize button {
      color: $secondary !important;
    }
  }

  .btn,
  .capsule-container .capsule-btn {
    padding: 15px 18px;
  }

  .modal-header.top-close .close {
    right: -10px !important;
    top: -10px;
  }
  .vayco-auth .vayco-public.login_singup .public-header::before {
    width: 94% !important;
  }
  .qrModal {
    .poweredBy {
      margin-top: 10px;
      margin-bottom: 10px;
      position: unset;
      text-align: center;
      img {
        width: 80px;
      }
    }
  }
  .vayco-auth {
    .footer.public {
      .left {
        width: 100%;
      }
      .powered-by {
        width: 100%;
        text-align: center;
      }
    }
  }

  .authWrapper .leftSide {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .page_Inner_header {
    .page_title {
      h1 {
        font-size: 18px;
      }
      .backtopage {
        padding: 0 20px 0 0 !important;
      }
    }
    a {
      padding: 16px 20px !important;
      font-size: 14px;
    }
  }
  .calendar_box {
    margin-top: 30px !important;
  }
  .booking_card {
    flex: 0 0 100%;
    max-width: 100%;
  }
  button {
    margin-bottom: 0 !important;
  }
  .wedding-group-chat {
    .nav.nav-tabs {
      flex-direction: column;
      a {
        &.nav-link.btn-primary {
          margin-bottom: 20px;
        }
      }
    }
  }
  .vayco-auth .vayco-public.login_singup .public-header::before {
    width: 90% !important;
  }
  .success_message_stronger {
    padding: 0 10px;
    p {
      text-align: center;
      font-size: 18px;
    }
    .facebookSocial {
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }

  .addInputs,
  .removeInputs {
    width: 24px;
    height: 24px;
  }

  .authWrapper .rightSide .loginWrapper {
    padding: 20px;
    max-width: 100% !important;
    margin: 0 auto 10px;

    .projectLogo {
      height: 100px !important;
    }

    h4 {
      font-size: 22px !important;
    }
  }
}

@media screen and (max-width: 640px) {
  .vayco-public .app {
    padding: 0px !important;
  }
  .vayco-auth .vayco-public.login_singup .public-header::before {
    width: 100% !important;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }
  .loginLink {
    color: $white !important;
  }
}

@media screen and (max-width: 575.98px) {
  body,
  html {
    overflow-x: hidden;
  }
  .btn-second {
    padding: 6px 15px;
    font-size: 14px;
  }
  .main-panel {
    .content {
      padding: 20px 15px;
    }
  }
  .mobilePadding-0 {
    padding: 0;
  }
  .no-margin {
    margin-left: 0;
    margin-right: 0;
  }
  .tour_list,
  .booking_wrapper {
    margin-top: 20px;
  }
  .availability_wrapper {
    .list-group-item {
      flex-wrap: wrap;
      .schedule_time {
        margin: 10px 0 !important;
        width: 80%;
      }
      .form-group {
        margin: 0;
      }
    }
  }
  .app {
    padding: 10px;
  }

  .vayco-public {
    &.login_singup {
      .public-header {
        padding: 6px 14px 6px 10px !important;
        .company-logo {
          width: 120px;
        }
        &::before {
          width: 100% !important;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0) !important;
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0) !important;
        }
        .loginLink {
          color: $white !important;
          font-size: 16px !important;
          text-decoration: none !important;
        }
      }
    }
    .public-title {
      font-size: 16px !important;
    }
  }
  .login_wrapepr {
    .login_Container {
      padding: 100px 20px 30px;
      .form-title {
        margin-bottom: 30px !important;
        h2 {
          font-size: 26px !important;
        }
      }
    }
  }
  .vayco-auth .vayco-public .loginPgContent {
    border: none;
    .card .card-body {
      padding: 0;
    }
  }
  .hideMobile {
    display: none;
  }
  .Description {
    margin-top: 20px;
  }
  .btn-submit {
    height: auto;
    padding: 12px 20px;
  }
  .loginPgBg {
    align-items: flex-start;
    margin-top: 20px;
  }

  .header_top {
    padding: 10px !important;
    .container-fluid {
      padding: 0px;
    }
    .user_wrapper .userInfo {
      display: none;
    }
  }
  .vayco-admin .wedding-group-chat .capsule-container .capsule-btn {
    padding: 12px !important;
    font-size: 14px !important;
  }
  .Message img {
    width: 100px;
    height: 100px;
  }

  .qrModal {
    .modal-body {
      padding: 0 !important;
    }
    .modal-footer {
      padding: 0 0 10px !important;
      button {
        padding: 15px 20px !important;
        min-width: 120px;
      }
    }
    .qr-code {
      width: 200px;
      height: 200px;
    }
  }
  .varification {
    .captcha {
      div {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        canvas {
          padding: 10px;
          margin: 0;
          ~ div {
            display: block;
            width: 100%;
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .btn-primary,
  .btn-secondary,
  .btn-danger,
  .btn-primary-outline {
    min-width: 110px;
    font-size: 15px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  label,
  span {
    font-size: 14px !important;
  }

  .vayco-auth {
    .vayco-public {
      &.login_singup {
        .public-header {
          .public-logo {
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .navbar {
    .navbar-wrapper {
      span {
        display: none;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px !important;
  }

  .addUserModal {
    max-width: 480px !important;
  }
}

@media screen and (max-width: 480px) {
  //     .footer:not(.footer-big) nav > ul a {
  //         padding:0px 0px;
  //         margin: 10px 0px 0;
  //     }
  //     footer .copyright{
  //         margin-top: 0 !important;
  //     }
}

@media screen and (max-height: 780px) {
  .login_wrapepr {
    .login_Container {
      max-width: 600px !important;
      .form-title {
        margin-bottom: 10px !important;
        h2 {
          font-size: 20px !important;
        }
      }
      .form-group {
        label {
          margin-bottom: 6px !important;
          font-size: 12px !important;
        }
        .input-group {
          height: 36px !important;
          input,
          input::placeholder {
            font-size: 13px !important;
          }
        }
      }
      .btn-submit {
        margin-top: 15px !important;
        &.login_btn {
          padding: 10px 40px !important;
          font-size: 13px !important;
        }
      }
      .socialLogin {
        button {
          padding: 10px 40px !important;
          font-size: 14px !important;
        }
      }
      .forgotPassword {
        font-size: 12px;
      }
    }
  }
  .public-header {
    img {
      width: 90px !important;
    }
    .loginLink {
      font-size: 14px !important;
      padding-right: 0 !important;
    }
  }
  // .footer.public {
  //   span {
  //     font-size: 12px !important;
  //   }
  // }
  .vayco-auth .vayco-public form {
    .public-title {
      font-size: 15px;
    }
  }
  .floating-chat-container {
    &.maximize {
      width: 500px;
    }
    .chat-card > {
      .card {
        .card-body {
          &.messageChatWrapper {
            height: 200px !important;
          }
        }
      }
    }
  }
  .vayco-admin {
    .wedding-group-chat.requests {
      .chat-top-strip {
        margin: 10px;
        ul {
          li {
            font-size: 11px !important;
          }
        }
      }
      .request-card-details {
        padding: 10px !important ;
        .fa-pencil-alt {
          margin-right: 4px;
        }
        ul {
          li {
            padding-right: 6px;
            span,
            b {
              font-size: 11px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 560px) {
  .login_wrapepr {
    .login_left {
      flex: 0 0 40% !important;
    }
    .login_right {
      .login_Container {
        max-width: 500px !important;
        .or {
          margin: 10px 0 !important;
          span {
            width: 30px !important;
            height: 30px !important;
            font-size: 9px !important;
          }
        }
        .socialLogin {
          button {
            font-size: 12px !important;
            padding: 8px 20px !important;
            img {
              height: 18px !important;
            }
          }
        }
      }
    }
  }
  .public-header {
    img {
      width: 74px !important;
    }
    .loginLink {
      font-size: 12px !important;
    }
  }
  .floating-chat-container {
    &.maximize {
      width: 400px;
    }
    .chat-card > {
      .card {
        .card-body {
          &.messageChatWrapper {
            height: 150px !important;
          }
          &.cardInfo {
            padding-top: 0px !important;
          }
        }
      }
    }
  }
  .vayco-admin {
    .wedding-group-chat.requests {
      .chat-top-strip {
        margin: 10px;
        padding: 8px;
        ul {
          li {
            font-size: 9px !important;
          }
        }
      }
      .request-card-details {
        padding: 10px !important ;
        .fa-pencil-alt {
          margin-right: 4px;
        }
        ul {
          li {
            padding-right: 6px;
            span,
            b {
              font-size: 9px !important;
            }
          }
        }
        form {
          margin: 0;
          input,
          textarea {
            height: 30px !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
          textarea {
            padding-top: 4px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-large {
    max-width: 1200px !important;
  }
}

@media screen and (max-height: 488px) {
  .login_wrapepr {
    .login_Container {
      padding: 80px 30px 0px !important;
      .form-group {
        margin-bottom: 10px !important;
      }
      .forgotPassword {
        font-size: 14px !important;
      }
    }
  }
}
