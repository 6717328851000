#root ~ iframe {
  display: none !important;
}

.fa-map-marker,
.fa-pencil,
.fa-trash-o,
.fa-bookmark-o {
  font-family: "FontAwesome" !important;
}

body {
  overflow-x: hidden;
  cursor: inherit;
}

html > canvas {
  width: 120px !important;
  display: none !important;
}

.login_singup {
  .app {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
}

.text-wrap-overflow {
  width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn,
.btn-primary-outline,
.btn-primary {
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 14px 24px;
  border-radius: 10px;
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

ul {
  margin-bottom: 0;
}

.w-20 {
  width: 20px !important;
}

.w-auto {
  width: auto !important;
}

.bg-theme {
  background-color: $primary !important;
}

.text-green {
  color: #63ab5e !important;
}

.text-theme {
  color: $primary !important;
}

.text-para {
  color: #707070;
}

.text-red {
  color: $red !important;
}

.text-danger {
  color: #c13e2c !important;
}

.text-grey,
label,
span {
  color: $lightGray !important;
}

.text-second {
  color: $secondary !important;
}

.text-blue {
  color: $secondary !important;
}

.text-darkblue {
  color: $darkblue !important;
}

.text-navy-blue {
  color: $Navyblue !important;
}

.btn-link:hover {
  text-decoration: underline !important;
  color: $secondary !important;
}

.btn-clear {
  padding: 15px 20px !important;
  border-radius: 7px !important;
}

.btn-transparent {
  background-color: transparent !important;
  color: #333 !important;
  border: none;
}

.btn.btn-light {
  color: #212529 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.addInputs,
.removeInputs {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

// ===============
.accepted {
  background-color: #a9f4cd !important;
  color: #62b383 !important;
}

label {
  gap: 10px;
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-bottom: 10px !important;
  line-height: 1.3 !important;
}

.card label {
  text-transform: none;
}

.cursor-pointer,
.custom-switch,
.action i,
.fa-pencil {
  cursor: pointer;
}

.mw-130 {
  min-width: 130px;
}

.mw-150 {
  min-width: 150px;
}

.lh-1 {
  line-height: 1 !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-30 {
  font-size: 26px !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-light {
  font-weight: lighter !important;
}

.rounded {
  border-radius: 12px !important;
}

.form-control,
.form-control::placeholder,
.form-control {
  font-size: 14px;
  color: #9d9fa1;
}

label,
span {
  font-size: 15px;
  font-weight: 400;
}

.form-group {
  position: relative;
  .form-group {
    margin-bottom: 0 !important;
  }

  .form-control::placeholder {
    color: #9d9fa1;
  }
  .form-control {
    border: 1px solid $border-light !important;
    border-radius: 7px;
    &:focus {
      border-color: $primary !important;
    }
  }

  .custom-control {
    display: flex;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #092745;
    background-color: #092745;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }

  select {
    min-width: 150px;
  }

  .input-group-text .fa-search {
    font-size: 16px;
  }
}

.card-box {
  padding: 10px 20px 20px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 35px;
}

.countryCode {
  .input-group-text {
    margin-right: 0px !important;
    img {
      margin-right: 6px;
    }
    select {
      min-width: inherit;
      padding-right: 0px !important;
    }
  }
}

// ======== Custom Checkbox ===============

.check-box-container {
  color: #919191 !important;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .custom-checkbox {
    margin-bottom: 10px !important;
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
}

.custom-control-label {
  margin-bottom: 0 !important;
  padding-left: 2px;
  cursor: pointer;
  &::after,
  &::before {
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 1.3rem;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  color: #43a756 !important;
}

.custom-color {
  z-index: 0;
  .custom-control-label {
    font-size: 15px;
    font-weight: 600;
    color: $primary !important;
  }
}

// =============

.card {
  border-radius: 10px;
  overflow: visible !important;
  padding: 15px;
  border: none !important;
  box-shadow: none !important;
  .card-header {
    padding: 15px !important;
  }
}

.back-btn {
  position: absolute;
  left: 20px;
  display: flex;
  align-items: center;
  color: black !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;

  span {
    margin-top: 2px;
    margin-left: 3px;
  }
}

.btn {
  margin-bottom: 0;
  font-size: 16px;
}

.rdw-editor-main {
  border: 1px solid #dad8d8;
  border-top: 0;
  border-radius: 5px;
  padding: 5px;
}

.rdw-editor-toolbar {
  border-color: #dad8d8 !important;
  margin-bottom: 0 !important;
}

.rdw-editor-main,
.rdw-editor-toolbar {
  border-radius: 0 !important;
  background: #eff0f6;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.public-DraftStyleDefault-ltr {
  min-height: 120px;
}

.fa-phone {
  transform: (rotate(90deg));
}

// === Chat Profile ====
.all-members-chat-container {
  max-height: 520px;
  overflow: hidden;
  overflow-y: auto;
}

.mamber_header {
  i {
    display: none;
  }
}
.chat-card {
  .media-heading {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
  }
}

.chat-profile {
  cursor: pointer;
  .media-left {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    overflow: hidden;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .media-body {
    flex: 1;
  }
}

// ======= Modal =======
.modal {
  padding: 14px;

  .modal-dialog {
    padding: 0 !important;
  }

  .modal-content {
    padding: 20px !important;

    .modal-header {
      padding-top: 0 !important;
      border-bottom: 1px solid #dee2e6 !important;
      .modal-title {
        width: 100%;
        font-weight: 600;
      }

      .close {
        right: 25px !important;
        top: 20px;
        outline: none !important;
      }

      &.top-close .close {
        right: -20px !important;
        top: -20px;
        outline: none !important;
        background: white !important;
        width: 40px;
        height: 40px;
        border: 1px solid #c4c4c4;
        border-radius: 100%;
        font-size: 22px !important;

        span {
          margin-top: 2px;
          display: block;
        }
      }
    }

    .modal-footer {
      border-top: 1px solid #dee2e6 !important;
      padding-bottom: 0 !important;

      .btn {
        padding: 14px 30px;
      }
    }
  }
}

// ======= Capsule Container with bttn =======
.tabsCustom {
  width: 100%;
}
.capsule-container {
  display: flex;
  align-items: center;
  overflow: scroll;
  overflow-y: hidden;
  width: 100%;
  padding-bottom: 8px;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #c0c0c0;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar:vertical {
    display: none;
  }

  .capsule-btn {
    position: relative;
    cursor: pointer;
    color: black !important;
    font-weight: bold;
    padding: 12px 20px;
    background-color: white;
    border: none !important;
    transition: all 0.3s;
    border-bottom: 2px solid transparent !important;
    border-right: 1px solid $border-light !important;

    span {
      font-weight: initial;
    }
    .input-group .input-group-text {
      cursor: inherit;
      .fa-times {
        cursor: pointer;
      }
    }
  }

  .capsule-btn:nth-child(1),
  .capsule-btn:nth-last-child(1) {
    border-radius: 8px;
  }

  .capsule-btn:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .capsule-btn:nth-last-child(1) {
    border-radius: 8px;
    border-color: transparent !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // ========= Bttn =========
  &.bttn {
    .capsule-btn::before,
    .active.capsule-btn::before {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      transform: scale(0);
      transition: all 0.1s linear;
      background-color: $primary;
    }

    .capsule-btn:hover::before,
    .active.capsule-btn::before {
      transform: scale(1);
    }
  }
}

// ======= Search Box ===========
.searchBox {
  .input-group {
    border-color: transparent;
    background-color: #d6d6d659;
    padding: 1px;
    box-sizing: border-box;
    border-radius: 10px;
    &:focus-within {
      border-color: $primary;
    }
    .form-control {
      overflow: hidden;
      background-color: #fff !important;
      height: 55px;
      border: none;
      font-size: 16px;
      padding-left: 20px !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      & ~ .input-group-text {
        background-color: white;
        border-color: transparent;
        border: none;
        color: #d6d6d671;
        cursor: pointer;
        padding-right: 20px !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &::placeholder {
        color: #a5a5a5;
      }
    }
  }
}

// ====== Table =====

.table-responsive {
  overflow: auto !important;
  .table {
    white-space: nowrap;
    &.table-fixed {
      table-layout: fixed;
      .text-wrap-overflow {
        > * {
          width: 100%;
          padding-right: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.table-responsive,
.table {
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
}

// .table thead {
//   background-color: $table-bg;
// }

.table {
  tr {
    border-bottom: 1px solid $border-light;
  }
  tbody {
    tr {
      td {
        .tableImage {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          margin-right: 8px;
        }
      }
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}

.table,
.table th,
.table td {
  border: none !important;
}

.table {
  th,
  td {
    font-size: 14px !important;
    padding: 15px 25px !important;
  }
}

.table th {
  font-weight: 800 !important;
  text-transform: capitalize !important;
  color: $secondary !important;
}

.table td {
  color: #919191 !important;

  > a:not(.btn-primary),
  .btn_link:not(.btn-primary) {
    color: $primary !important;
    text-decoration: underline;
    background: transparent !important;
    &:hover,
    &:focus {
      box-shadow: none !important;
    }
  }
  > span a {
    color: $primary !important;
    text-decoration: underline;
  }
  select {
    min-width: 100px;
    height: 44px;
    font-size: 15px;
    border-color: #d1ecf1;
    background-color: #d1ecf1 !important;
  }

  input {
    border: 1px solid black;
  }

  .btn-primary-outline {
    min-width: 100px;
    height: 44px;
    padding: 3px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

// ====================
.table th > .check-box-container,
.table td > .check-box-container {
  align-items: center;
  .custom-checkbox {
    margin: 0 !important;

    .custom-control-label::after,
    .custom-control-label::before {
      top: 0.25rem !important;
      transform: none !important;
    }
  }
}

// =================
.fa-envelope {
  position: relative;
  .badge {
    position: absolute;
    top: -10px;
    left: 50%;
    pointer-events: none;
    font-size: 10px !important;
  }
}

.searchName {
  .input-group {
    padding: 0 4px !important;
  }
}

// =========== Floating Chat Contianer ===========
.floating-chat-container {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: auto;
  z-index: 999;
  border-radius: 7px 7px 0;
  // border: 1px solid $border-light;
  box-shadow: 0px 0px 10px -3px #00000045;
  &.maximize {
    width: 650px;
  }
  .chat-card {
    > .card {
      > .card-header {
        height: 15%;
      }
      .card-body.cardInfo {
        height: auto !important;
        margin-bottom: 10px;
        overflow: visible !important;
        .request-card-details {
          overflow: visible !important;
        }
      }
      > .card-body {
        height: 300px !important;
        overflow-y: auto;
        min-height: auto !important;
        padding: 10px;
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #fff;
        }
        &::-webkit-scrollbar {
          width: 8px;
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba($color: lightGray, $alpha: 0.8);
          visibility: hidden;
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            visibility: visible;
          }
        }
      }

      > .card-footer {
        height: 15%;
        padding: 10px !important;
      }

      .chat-input-box {
        > * {
          height: 40px;
        }

        .input-box textarea {
          line-height: 1;
          margin-top: 0px;
          padding: 12px 0;
        }

        .send-button button {
          width: 40px;
        }
      }
    }
  }
  .windows-icon {
    margin-top: -5px;
    padding-left: 20px;
  }

  .Message {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}

// chat box
.ChatDate {
  text-align: center;
  margin-bottom: 10px;
  color: $lightGray;
  font-size: 14px;
  color: $secondary;
  font-weight: 500;
}
.Message {
  margin-bottom: 10px;
  img {
    max-width: 300px;
    border-radius: 14px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  &.message-sender {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    .message-content {
      border-radius: 10px 10px 0px 10px;
    }
    .time {
      max-width: 100px;
      text-align: right;
    }
    .greyCheck {
      color: $lightGray;
      font-size: 12px;
    }
    button {
      background-color: transparent;
      color: $black;
      font-size: 13px;
      &:focus,
      &:active,
      &:focus:active {
        background-color: transparent !important;
        color: currentColor;
      }
      &:focus {
        color: $lightGray;
      }
    }
    .dropdown-menu {
      transition: unset !important;
      box-shadow: none;
    }
  }
  &.message-reciever {
    .message-content {
      border-radius: 10px 10px 10px 0px;
    }
  }
  .message-content {
    display: inline-block;
    padding: 8px 15px;
    max-width: calc(100% - 80px);
    background-color: $table-bg;
    color: #929292;
    font-size: 14px;
  }
  .time {
    display: block;
    font-size: 12px;
    color: #929292;
    opacity: 0.6;
  }
}

// preview
.filePreview {
  ul {
    padding: 15px 0 0;
    list-style: none;
    display: flex;
    li {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      position: relative;
      margin-right: 10px;
      border: 2px solid #f5f5f5;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 50px;
      }
      i {
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: $white;
        width: 16px;
        height: 16px;
        border-radius: 10px;
        font-size: 10px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px 0px rgba($color: #000000, $alpha: 0.2);
        &:hover {
          background-color: $error;
          color: $white;
        }
      }
    }
  }
}

// =========== Pagination ===========
.pagination .page-item .page-link,
.pagination .page-item,
.pagination .previous a,
.pagination .next a {
  border-radius: 50%;
  margin: 0 5px;
  color: #797979 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 6px 12px;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.pagination .page-item.active a {
  background: linear-gradient(to bottom, $primary, $secondary);
  font-size: 13px;
  color: white !important;
  border-radius: 6px;
}

// ========= Setting Chips ==========
.settingsWrap {
  .settingChips {
    box-shadow: none !important;
    border: 1px solid #dee2e6 !important;
    .styles_chip__i-PT7.styles_show__3kLFl {
      padding: 5px 10px 4px;
      line-height: 1;
      font-size: 15px;
      color: $primary;
      text-transform: capitalize;
      border-radius: 6px;
      border-color: $primary;
      background-color: transparent !important;

      .styles_cursor-pointer__2Jvz5 {
        fill: $red;
        font-weight: 700;
      }

      .form-control,
      .form-control:focus {
        font-size: 15px;
        background-color: transparent !important;
      }
    }
  }

  .group_image {
    background-color: white;
  }

  // .btn-cancel {
  //   padding: 15px 24px;
  // }

  .equal_width button {
    padding: 14px !important;
    line-height: 1;
  }

  .table {
    .unread-request {
      background-color: rgba(242, 245, 245, 0.8);
    }
    td {
      vertical-align: top !important;
      > * {
        min-height: 46px;
      }

      &.td-action {
        .d-none {
          display: none !important;
        }
        i {
          cursor: pointer;
          font-size: 18px;

          &:not(:last-child) {
            margin-right: 17px;
          }
        }

        .fa-plus,
        .fa-trash-alt {
          margin-right: 2px;
        }

        .fa-pencil-alt,
        .fa-trash-alt {
          margin-top: 1px;
        }
      }

      .amenity-location {
        label {
          margin-bottom: 0 !important;
        }
        .replace_map {
          width: auto !important;
        }
        .form-error {
          padding-left: 0 !important;
        }
      }
    }

    .form-control {
      border-color: #e4e7ea;
      height: 46px;
      line-height: 1;
      font-size: 15px;
    }
  }
}

.equal_width button {
  min-width: 100px;
  padding-right: 0;
  padding-left: 0;
}

.box-container {
  background: white;
  padding: 20px;
  text-align: left;
  margin: 10px 0 25px;
  border: 1px solid #c8ced3 !important;
  border-radius: 15px;

  h2 {
    color: #1f1f1f !important;
    font-size: 35px;
    font-weight: 700;
  }

  .BtnPurple,
  .btn {
    text-align: center !important;
  }

  .form-control {
    background-color: #eff0f6 !important;
  }

  .input-group {
    padding: 0;
    background-color: white;
  }

  .input-group-text {
    border: none !important;
  }

  input {
    height: 45px !important;
  }
}

.dateRange {
  height: 48px;
  > .input-group {
    height: 100%;
    > .input-group-prepend,
    > .DateRangePicker {
      height: 100%;
    }

    .input-group-text {
      border: none !important;
    }
  }

  .DateRangePicker {
    .DateRangePickerInput__withBorder {
      display: flex !important;
      align-items: center;
      border-radius: 8px !important;
      border: 0 !important;
      background-color: white !important;
      width: 100%;

      .DateInput {
        width: calc(50% - 12px) !important;
        border-radius: 8px;
        .DateInput_input {
          display: block;
          width: 100%;
          height: 46px;
          font-weight: 400 !important;
          line-height: 1.5 !important;
          background-clip: padding-box;
          color: #828282 !important;
          font-family: Poppins, sans-serif;
          font-size: 14px !important;
          border: none !important;
          padding: 6px 8px !important;
          background-color: white !important;
          outline: 0 !important;
        }
      }
    }

    .DateRangePickerInput_arrow {
      height: auto !important;
      .DateRangePickerInput_arrow_svg {
        width: 20px !important;
        height: 20px !important;
        margin-top: -4px;
        margin-right: 4px;
      }
    }
  }
}

.DateRangePicker_picker {
  z-index: 99 !important;
}

.CalendarMonth_caption {
  font-size: 16px !important;
  color: #000 !important;
}

.DayPicker_weekHeader_li {
  color: #666;
}

.CalendarDay__default {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: $black !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $primary !important;
  color: #fff !important;
  border-color: $primary !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__selected_span:hover {
  background: rgba(255, 115, 0, 0.5) !important;
  border-color: rgba(255, 115, 0, 0.5) !important;
}

.CalendarMonth_table {
  margin-top: 3px;
  box-shadow: none;
}

.DayPicker__withBorder {
  border-radius: 4px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

// date-range
.dateRange {
  // width: 246px;
  display: block !important;

  .react-daterange-picker__wrapper {
    padding: 6px 8px;
    height: 48px;
    background-color: #fff;
    border: none;
    border-radius: 0;

    .react-daterange-picker__clear-button,
    .react-daterange-picker__calendar-button {
      display: none;
    }

    .react-daterange-picker__inputGroup {
      display: flex;
      align-items: center;
    }

    .react-daterange-picker__range-divider {
      margin: 0 10px;
    }

    .react-daterange-picker__inputGroup,
    .react-daterange-picker__inputGroup input {
      color: $grey;
      outline: 0 !important;
      min-width: unset;
      color: $grey;
      font-size: 14px;
    }

    .react-daterange-picker__inputGroup {
      flex: 0;
    }

    .react-daterange-picker__range-divider {
      margin: 0 10px;
    }
  }

  input,
  .react-date-picker__inputGroup__divider,
  .react-daterange-picker__range-divider {
    color: #9d9fa1 !important;
  }
}

.react-calendar__tile--active {
  background: $primary !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;

  button {
    color: $grey;
    font-size: 21px;
    min-width: 36px !important;
  }
}

.react-daterange-picker__calendar {
  margin: 3px auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 99 !important;
}

.react-calendar__navigation__label {
  font-size: 14px !important;
  font-weight: 500;
  margin-top: 4px !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  font-weight: 600;
  padding: 4px !important;
}

.react-calendar {
  border: 0 !important;
}

.react-daterange-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}

.profile {
  .coverPhoto {
    cursor: pointer;
    overflow: visible;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    i {
      font-size: 50px;
    }

    .edit-icon {
      font-size: 15px;
      color: white;
      border-radius: 100%;
      padding: 10px;
      position: absolute;
      bottom: 1%;
      right: 5px;
      background-color: $primary;
      transform: translate(0, -50%);
    }
  }
}

// ========== Resort ==========
.check-switch {
  tr > :first-child {
    padding: 15px 0px 15px 25px !important;
  }
  .custom-switch {
    .custom-control-label {
      width: 70px !important;
      margin-left: 70px;
    }

    .custom-control-label::before {
      top: 50% !important;
      left: -70px;
      transform: translateY(-50%) !important;
    }

    .custom-control-label::after {
      top: 50% !important;
      left: -68px;
      transform: translateX(0px) translateY(-50%) !important;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      left: -70px;
      transform: translateX(36px) translateY(-50%) !important;
    }
  }
}

.innerForm {
  padding: 20px;
  border-radius: 10px;
  background: white;
}

// ========== Tabs ===========
.tabs {
  .nav-item {
    flex: 1;
    .nav-link {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      border-bottom: 1px solid #e3e3e3;
      color: #555555;
      &.active {
        color: #4c9aa4;
        border-bottom: 1px solid #4c9aa4;
      }
    }
  }

  .tab-content {
    margin-top: 10px;
  }
}

.sidebarOverlay {
  display: none;
}

// ===== Comments =====
.commentSection {
  .comments-list {
    .alert {
      color: #333333;
      background-color: #f1fdff !important;

      button.close {
        right: -10px;
        top: 0%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ff0f0f;

        > span {
          color: white;
          padding: 7px !important;
          font-size: 80%;
        }
      }
    }

    .media {
      img {
        width: 45px;
      }
      .media-heading {
        font-size: 22px;
      }
    }
  }

  .add-comments {
    position: relative;
    margin-bottom: 20px;
    padding: 0 10px 5px;
    margin-top: -10px;
    max-height: 380px;
    overflow: auto;

    textarea,
    textarea::placeholder {
      font-size: 16px;
      color: #9d9fa1;
      max-height: 110px;
      border-radius: 0 !important;
      background-color: transparent !important;
    }

    .sendMsg {
      background: transparent !important;
      padding: 0 4px;
      height: 40px;
      line-height: 1;
      border: 0 !important;
      position: relative;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      position: absolute;
      right: 15px;
      bottom: 3px;
    }
  }
}

// ====== React Chips =======
.react-chip {
  > div > div:not(:last-child) {
    background-color: $secondary !important;
    color: white !important;
  }
}

// ===== Custom File Upload =======
.custom-file-upload {
  display: flex;
  justify-content: center;
  label {
    position: relative;
    cursor: pointer;
    margin: 0 auto 30px;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    padding: 10px;
    border: 3px solid $border-light;

    input {
      display: none !important;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }

    i {
      position: absolute;
      top: 80%;
      right: 10px;
      font-size: 22px;
      color: $primary;
    }
  }
}

// ===== success_message_stronger ====
.success_message_stronger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  position: relative;
  z-index: 1;
  background-image: url("../img//flag.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.9);
  }

  h1 {
    color: #219d4b;
    font-weight: 600;
  }

  p {
    font-size: 22px;
    margin-bottom: 3px;
  }
  .socialMedia {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    li {
      a {
        padding: 10px 20px;
        color: $mediumGray;
        i {
          font-size: 22px;
        }
        &.facebook {
          i {
            color: #1877f2;
          }
        }
        &.twitter {
          i {
            color: #1da1f2;
          }
        }
        &.instagram {
          i {
            color: #e4405f;
          }
        }
        &.linkedin {
          i {
            color: #0a66c2;
          }
        }
        &.youtube {
          i {
            color: #cd201f;
          }
        }
      }
    }
  }
}
.react-select {
  z-index: 9;
}
//==== varification captcha ====

.varification {
  .captcha {
    div {
      display: flex;
      align-items: center;
      canvas {
        border: 1px solid rgba($color: lightGray, $alpha: 0.7);
        padding: 15px 20px;
        border-radius: 6px;
        margin-right: 10px;

        ~ div {
          a {
            color: $black !important;
            font-weight: 500;
            font-family: $Poppins;
          }
        }
      }
    }
  }
  input {
    max-width: 300px;
    width: 100%;
    border: 1px solid $border-light;
    margin: 10px 0;
  }
}

// Service Country List
.serviceCountryList {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 25px;
  margin-bottom: 30px;

  li {
    .form-group {
      margin-bottom: 0;
    }
    label {
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $border-light;
    }
  }
}

// varification
// .verification {
//   margin-top: 30px;
// }
