@import "./light-bootstrap-dashboard-pro-react.scss";
@import "./variables";
@import "./custom";
@import "./common";
$theme-color: #25215e;
body {
  font-family: "Aria", sans-serif;
  color: $grey;
}

.vayco-auth {
  background-color: #f3f3f3;
}

p {
  font-size: 16px;
}

// unique class
.space_remove {
  margin: 0 !important;
}

.Label_wtih_Button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  label {
    margin: 0;
  }
}

// button
.btn {
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 0.5px;
  border: none !important;
  text-transform: capitalize;
  transition: transform 0.2s linear;

  &:active {
    transform: scale(0.8);
  }
}

.btn-primary,
.btn-secondary,
.btn-danger,
.btn-primary-outline {
  min-width: 180px;
  max-width: 300px;
}

.btn-secondary {
  color: $secondary !important;
  background-color: #d3d3d3 !important;
}

.btn-danger {
  color: white !important;
  background-color: #c13e2c !important;
}

.btn-primary {
  background: $secondary !important;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 5px 10px 0 rgba($secondary, 0.2) !important;
    background-color: $secondary;
  }
  &-outline {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    color: $secondary !important;
    border-color: $secondary !important;
    overflow: hidden;
  }
}

.btn-sm.btn {
  padding: 10px 30px;
  border-radius: 6px !important;
  font-size: 14px;
}

.btn-addMore {
  background-color: transparent;
  color: $secondary;
  padding: 0;
  text-decoration: underline !important;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: transparent !important;
    color: $secondary !important;
  }
  &.remove {
    color: $error;
    text-decoration: none !important;
    &:hover {
      color: $error !important;
    }
  }
}

.btn-simple {
  padding: 0 6px;
  margin: 0 !important;
  font-size: 14px;
  &:hover {
    color: $primary !important;
  }
}

.btn-second {
  background-color: transparent !important;
  border-color: $primary;
  font-weight: 400 !important;
  color: $primary !important;
  margin: 0 !important;
  padding: 8px 16px !important;
  width: auto;
  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(to right, $primary, $secondary);
    border-color: transparent !important;
    outline: none !important;
    color: $white !important;
    box-shadow: none !important;
  }
}

.btn-remove {
  background-color: rgb(252, 67, 67);
  opacity: 1;
  border-color: transparent;
  padding: 6px 20px;
  font-weight: 500;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    color: rgb(252, 67, 67) !important;
    border-color: rgb(252, 67, 67) !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-submit {
  padding: 15px 40px;
  margin-top: 30px;
  &.login_btn {
    padding: 14px 60px !important;
  }
}

.btn-cancel {
  margin-right: 15px;
  background: transparent !important;
  color: $primary;
  border-radius: 6px;
  border-width: 1px;
  line-height: normal;
  border: 1px solid $primary !important;
  &:hover,
  &:focus,
  &:focus:active,
  &:active {
    border-color: $primary;
    color: $primary !important;
    background: linear-gradient(to right, $primary, $secondary);
  }
}

// Card
.card {
  border-color: #f5f5f5;
  box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.09);
}

// pagination
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  .page-item {
    a {
      border: none;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent !important;
      font-size: 13px;

      &:hover {
        color: $primary;
      }
    }
    &.active {
      span {
        background: linear-gradient(to bottom, $primary, $secondary);
        font-size: 13px;
        border-radius: 6px;
      }
    }
  }
}

.showng_entries {
  font-size: 14px;
  color: #acacac;
}

// page sidebar
.sidebar {
  width: 280px;
  &::before {
    background-color: $secondary;
    opacity: 1;
  }
  .sidebar-wrapper {
    width: 100%;
    .logo {
      padding: 20px 0;
      border-bottom: 1px solid $primary;
      &::before {
        display: none;
      }
      .simple-text {
        display: block !important;
        text-align: center;
        margin: auto;
        .logo-img {
          width: 100%;
          max-height: 100%;
          display: block;
          text-align: center;
          img {
            width: 120px;
            position: unset;
            margin: auto;
            &.logo-mini {
              width: 40px;
              display: none;
            }
          }
        }
      }
    }
  }

  .nav {
    margin-top: 30px;
    .nav-item {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 4px;
        top: 0;
        width: 4px;
        height: 100%;
        background: transparent;
        border-radius: 50px;
      }
      .nav-link {
        display: flex;
        align-items: center;
        margin: 10px 25px;
        border-radius: 4px;
        position: relative;
        padding: 8px 15px;
        i {
          font-size: 20px;
          color: $white;
          font-weight: 600;
        }
        p {
          color: $white;
          text-transform: capitalize;
          font-size: 14px;
        }
      }
      &.active,
      &:hover {
        a {
          // background: linear-gradient(to right, $primary,$yellow) !important;
          background: linear-gradient(
            to right,
            $primary,
            $secondary
          ) !important;
          i,
          p {
            color: $white;
          }
        }
        &::before {
          background: linear-gradient(to right, $primary, $secondary);
        }
      }
    }
  }
}
// main panel
.main-panel {
  width: calc(100% - 280px);
}

// page header
.navbar-minimize {
  button {
    background-color: transparent !important;
    color: $white !important;
    border: none !important;
    padding: 0;
    font-size: 20px !important;
    margin: 0 !important;
    .menudir {
      width: 16px;
      transform: rotate(180deg);
    }
    &:hover,
    &:focus,
    &:active {
      color: $primary !important;
      border: none !important;
      outline: none;
      box-shadow: none !important;
    }
  }
}

.logo_icon {
  position: absolute;
  bottom: 0;
  -webkit-transform: translate3d(10px, 20px, 0px);
  transform: translate3d(-57px, 3px, 0px);
  z-index: 2;
  img {
    width: 160px;
    opacity: 0.1;
  }
}
.navbar {
  &.header_top {
    border-bottom: none !important;
    position: relative;
    z-index: 3;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 92%;
      height: 100%;
      background: linear-gradient(to right, $primary, $secondary);
      -webkit-clip-path: polygon(78% 0, 82% 100%, 0 100%, 0 0);
      clip-path: polygon(78% 0, 82% 100%, 0 100%, 0 0);
      z-index: -1;
    }
  }

  .navbar-nav {
    .dropdown-toggle {
      &::after {
        display: none;
      }
      i {
        font-size: 16px !important;
        margin-left: 8px;
        color: rgba($lightGray, 0.5);
      }
    }
    .notification_dropdown {
      a {
        i {
          font-size: 20px !important;
        }
      }
      .dropdown-menu {
        a {
          &:last-child {
            color: $primary;
          }
        }
      }
    }
    .notification {
      min-width: unset;
      padding: 0;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      left: auto;
      right: 12px;
      top: 18px;
    }
  }
  .collapse:not(.show) {
    display: block !important;
  }
}

.header_top {
  .container-fluid {
    flex-wrap: nowrap;
  }
}
.navbar .navbar-nav .nc-icon {
  font-size: 18px;
}
.header_right {
  flex-direction: row;
  justify-content: flex-end;
}

.user_wrapper {
  img {
    border-radius: 50px;
  }
  .userInfo {
    margin-left: 10px;
    line-height: unset;
    h3 {
      font-size: 15px;
      font-weight: 600;
      color: $secondary;
      line-height: 1;
      margin: 0;
    }
    span {
      display: block;
      line-height: 1;
      font-size: 14px;
      margin-top: 6px;
      color: rgba($lightGray, 0.7);
    }
  }
}
.main_inner {
  padding: 15px;
  border-radius: 8px;
}
.page_Inner_header {
  border-radius: 8px;
  margin-bottom: 25px;
  .page_title {
    display: flex;
    align-items: center;
    button {
      margin-left: 0px !important;
    }
    h1 {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      color: $secondary;

      .spinner-border {
        width: 1rem;
        margin-left: 6px;
        height: 1rem;
        border-width: 0.15em;
      }
    }
    .backtopage {
      padding: 0px 20px 0 0;
      margin: 0;
      font-size: 20px;
      color: $secondary;
    }

    .btn-link {
      text-decoration: none !important;
      color: $secondary;
      h4 {
        margin-left: 7px !important;
        font-size: 18px !important;
      }
    }
  }

  .input-group {
    max-width: 300px;
    background-color: $white;
    width: 100%;
    border: 1px solid #e7e7e7;
  }
  .orderiD {
    display: flex;
    justify-content: flex-end;
    span {
      max-width: 130px;
      margin-left: 5px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      font-weight: 500;
    }
  }

  a:not(.btn-link),
  button {
    margin: 0;
    padding: 16px 24px;
    margin-left: 10px !important;
    white-space: nowrap;
  }
}

.search-filter {
  padding: 0;
  overflow: visible;
  background-color: transparent;
  .form-control {
    background-color: $white !important;
  }
  .card-body {
    padding: 0;
  }
  .input-group {
    padding: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    input {
      height: 45px;
    }
    .input-group-text {
      background-color: $white !important;
    }
  }
}

// texteditor
.quill.editor .ql-container.ql-snow {
  height: 150px;
}

// form

label.subtitle {
  color: $lightGray;
  font-size: 14px;
  margin-bottom: 0;
}

.form-control {
  height: 48px;
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    border-color: $primary;
  }
}
// .react-select {
//   .react-select__control {
//     height: 48px;
//     background-color: #eff0f6 !important;
//     border: transparent !important;
//     .react-select__value-container {
//       width: 100%;
//     }
//   }
//   .react-select__control--is-focused {
//     border-color: $primary !important;
//   }
// }

// react select
.react-select-container {
  font-size: 13px;
  .react-select__control {
    color: #333;
    min-width: 150px;
    min-height: 48px;
    box-shadow: none;
    border-radius: 7px;
    border-color: $border-light !important;
    // border-color: $border !important;
    &--is-focused {
      border-color: #092745 !important;
    }
  }
  .react-select__placeholder {
    color: $lightGray;
  }
}

.react-select.form-control {
  padding: 0;
  color: $secondary;
  .__control {
    background-color: transparent;
    height: 100%;
    color: $secondary;
    border-color: transparent;
    border: none;
    box-shadow: none;
  }
  .__placeholder {
    color: $secondary;
  }
  .__indicators {
    color: $secondary !important;
  }
  .__dropdown-indicator {
    svg {
      color: $secondary;
    }
  }
  .__multi-value {
    background-color: rgba($yellow, 0.9);
  }
  .css-12jo7m5 {
    font-size: 90%;
    color: $white;
  }
  .css-xb97g8 {
    color: $white;
  }
}

.input-group {
  border: 1px solid $border-light;
  // background-color: #eef6ff;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 7px;
  &:focus-within {
    border-color: $primary;
  }
  .form-control {
    background-color: transparent;
    // height: 42px;
    // border: none;
    & ~ .input-group-text {
      background-color: transparent;
      // border-color: transparent;
      color: $secondary;
      cursor: pointer;
    }
  }

  .form-control {
    border: none !important;
    border-radius: 0 !important;
  }

  select.form-control {
    border-right: 1px solid $border-light !important;
  }
}

.form-check {
  padding-left: 0;
  .form-check-label {
    padding-left: 28px;
    font-size: 14px;
  }
  .form-check-sign {
    &::before {
      color: $primary;
    }
    &::after {
      color: $primary;
    }
  }
}

.form-check {
  min-height: 1px;
  display: inline-flex;
  align-items: center;
  input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
    &:focus {
      box-shadow: none;
    }
  }

  input[type="checkbox"] {
    min-width: 20px;
    cursor: pointer;
    margin-top: -2px;
  }

  input[type="radio"] {
    margin-top: 0px;
    margin-left: 0 !important;
  }

  label {
    cursor: pointer;
    user-select: none;
    color: $black;
    margin-left: 10px;
    padding-left: 15px !important;
    margin-bottom: 0 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
  box-shadow: 0 0 0px 1000px #fbfbfb inset;
}

// tour_list
.gridBox {
  padding: 0 20px 20px;
}
.tour_list {
  .row {
    margin: 0 -10px;
  }
  .title {
    text-align: left;
    margin-top: 0;
    font-size: 26px;
    font-weight: 600;
    color: #1e295c;
  }
  &:not(:first-child) {
    margin-top: 30px;
    border-top: 1px dashed #e1e1e1;
  }

  [class^="col-"] {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .card {
    height: 100% !important;
    border-radius: 8px !important;
    padding: 0 !important;
    box-shadow: none;
    border: 1px solid #f1f1f1 !important;
    .card-header {
      background-color: transparent;
      padding: 15px;
      border-bottom: 1px dashed rgba($lightGray, 0.5) !important;
    }
    .card-img-top {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: top center;
    }
    .card-body {
      padding: 5px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .card-title {
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
      }

      span {
        margin-top: 7px;
        font-size: 13px;
      }
    }
  }
}

// file upload
.fileUpload label {
  margin: 0;
  width: 90px;
  height: 90px;
  margin-top: 5px;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 15px;
  i {
    font-size: 24px;
    color: $lightGray;
  }
}
.form_label {
  color: $grey !important;
  text-transform: capitalize !important;
}
.form-error {
  display: block !important;
  color: #f16667;
  font-size: 13px;
  padding-left: 5px;
  margin: 3px 0 0;
}
//login

.login_wrapepr {
  .login_left {
    flex: 0 0 45%;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-image: url("../img/UH-60.jpg");
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .login_right {
    flex: 1;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.landing_buttons {
      .btn {
        margin: 0 10px !important;
      }
      .form-title {
        margin-bottom: 30px;
      }
    }
    &.with_lognform {
      justify-content: flex-start;
    }
  }
  .login_Container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 100px 30px 0px;

    .form-title {
      margin-bottom: 30px;
      h2 {
        text-align: center;
        font-weight: 900;
        font-size: 32px;
        color: $secondary;
        margin-bottom: 6px;
      }
      p {
        color: #919191;
        font-size: 16px;
        font-weight: 300;
      }
    }

    .form-group {
      margin-bottom: 20px;
      .input-group {
        padding: 0;
        background-color: transparent;
        border-bottom: 1px solid rgba($lightGray, 0.5);
        border-radius: 0;
        height: 46px;
        select {
          color: #919191;
          height: 100%;
        }
        .input-group-text {
          background-color: transparent;
          border: none;
          padding: 0;
          border-radius: 0;
          height: 100%;
          i {
            font-size: 16px;
            color: #cac7c7;
          }
        }
        .form-control {
          height: 100%;
        }
        &.countryCode {
          border: transparent;
          .input-group-text {
            margin-right: 15px;
            border-bottom: 1px solid rgba($lightGray, 0.5);
            select {
              border: none !important;
            }
          }
          .form-control {
            border-bottom: 1px solid rgba($lightGray, 0.5);
          }
        }
      }
      .form-control {
        background-color: transparent !important;
      }
      &.commentArea {
        .input-group {
          height: 120px !important;
          align-items: flex-start !important;
        }
        .input-group {
          span.input-group-text {
            height: auto;
            position: relative;
            top: 10px;
          }
        }
      }
    }
  }
  .socialLogin {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
      border: 1px solid rgba($lightGray, 0.3) !important;
      padding: 14px 30px;
      margin: 6px;
      height: auto;
      font-weight: 500;
      max-width: inherit;
      width: auto;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .or {
    text-align: center;
    margin: 24px 0 20px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      right: 0;
      top: 50%;
      width: 80%;
      height: 1px;
      transform: translate(-50%, -50%);

      border-bottom: 1px dashed rgba($black, 0.5);
      z-index: -1;
    }
    span {
      background-color: $white;
      width: 40px;
      height: 40px;
      border: 1px dashed rgba($black, 0.2);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.registerPage .login_wrapepr .login_Container {
  padding: 160px 30px 30px;
}

.loginPgContent {
  a {
    font-weight: 500;
  }
}
.singupContainer {
  max-width: 650px !important;
  margin: auto;
  .public-title {
    font-weight: 600;
    letter-spacing: 0 !important;
    font-size: 16px !important;
  }
}
.registerLoginBtn {
  color: #999;
  font-size: 15px;
  span {
    color: $primary;
  }
  &:hover {
    color: #999 !important;
    span {
      color: $primary !important;
    }
  }
}
// add excursiontour
.Gallery-wrapper {
  display: flex;
  overflow-x: hidden;

  .list-group {
    padding: 5px 0;
    overflow-x: auto;
    .list-group-item {
      padding: 0;
      margin-right: 15px;
      border: none;
      .item {
        width: 90px;
        height: 90px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        &.video {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0.4;
            border-radius: 4px;
          }
          .play {
            position: absolute;
            left: 50%;
            color: $white;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      p {
        text-align: center;
        margin: 5px 0;
        color: $lightGray;
        font-size: 14px;
      }
    }
  }
}

// close icon
.closeicon {
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
  background-color: $white;
  color: $error;
  opacity: 1;
  box-shadow: 2px -2px 0 0 rgba($black, 0.2);
  z-index: 0;
  font-weight: 800;
  padding: 4px;
  font-size: 12px;
  border-radius: 50px;
  &:hover {
    background-color: $error;
    color: $white;
  }
}
.availability_wrapper {
  .list-group-item {
    padding: 15px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .schedule_time {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 0 10px 0px;
    .form-group {
      margin: 0 10px;
      .form-control {
        height: 30px;
        border-radius: 50px;
      }
    }
  }
  .days {
    flex: 0 0 60px;
  }
  .schedule_clone {
    display: flex;
    flex: 0 0 80px;
    justify-content: flex-end;
    .btn-simple {
      padding: 0 8px;
    }
    .clone_option {
      button {
        &::after {
          display: none;
        }
      }
      .dropdown-menu {
        border-radius: 4px;
        border-color: transparent;
        box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.04),
          -6px -1px 0 0 rgba(0, 0, 0, 0.03), 4px 6px 0 0 rgba(0, 0, 0, 0.03);
        .dropdown-item-text {
          padding: 6px 14px;
          cursor: pointer !important;
          &:first-child {
            font-size: 14px;
            color: $lightGray;
          }
          &:last-child {
            padding: 10px 14px 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px dashed rgba($lightGray, 0.5);
            button {
              padding: 4px 20px !important;
              font-size: 14px;
              font-weight: 500;
              &.btn-cancel {
                border-color: $grey !important;
                color: $grey !important;
                &:hover {
                  background-color: $grey !important;
                  color: $white !important;
                }
              }
              &:first-child {
                background-color: $white;
                border-color: $primary;
                color: $primary;
                &:hover {
                  color: $white;
                }
              }
            }
          }
        }
        .copyTimes {
          display: flex;
          width: 200px;
          align-items: center;
          justify-content: space-between;
          label {
            margin: 0;
            width: auto;
            flex: auto;
            cursor: pointer;
          }
          .form-group {
            margin: 0;
            .form-check-sign {
              &::before,
              &::after {
                position: relative;
              }
              &::after {
                margin-left: -20px;
              }
            }
          }
        }
      }
    }
  }
}

.calendar_box {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px;
  .calendar-content {
    h3 {
      margin: 0;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      color: $lightGray;
    }
    button {
      background-color: transparent;
      padding: 8px 18px;
      width: 250px;
      color: $secondary;
      font-weight: 500;
      &:hover {
        background-color: $secondary;
        color: $white;
      }
    }
  }
}
.package-details {
  span {
    margin-right: 15px;
  }
}

.clonetime {
  display: flex;
  align-items: center;
  justify-content: center;
}

// modals

.modal-header {
  padding: 20px !important;
  // border-bottom: 1px dashed rgba($lightGray,0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  float: none;
  .modal-title {
    margin: 0 !important;
    text-align: center;
    color: #0c1025;
    font-weight: 600;
    font-size: 24px;
    .spinner-border {
      border-width: 0.2rem;
      position: relative;
      top: -3px;
      width: 1rem;
      height: 1rem;
    }
  }
  .close {
    color: #c4c4c4;
    font-size: 30px;
    padding: 0;
    margin: 0;
    opacity: 1;
    position: absolute;
    right: 10px;
    &:hover {
      color: $secondary;
    }
  }
}

.modal-header {
  padding: 10px !important;
}

// .modal-footer{
//     border-top: 1px dashed rgba($lightGray,0.5) !important;
//     justify-content: flex-end !important;
//     padding: 15px 0 !important;
//     button{
//         padding: 8px 30px !important;
//         margin: 0 10px !important;
//         font-weight: 500;
//         &.btn-cancel{
//             background-color: $white !important;
//             color: $grey;
//             &:hover,&:focus{
//                 outline: none !important;
//                 box-shadow: none !important;
//                 color: $grey !important;
//             }
//         }
//     }
// }

.bookingWrapper {
  .pgPadding {
    margin-bottom: 30px !important;
  }
}
.bookingDetails {
  height: 100%;
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 8px 0;
      display: flex;
      span {
        display: inline-block;
        &:first-child {
          font-weight: 600;
          width: 150px !important;
        }
        &:last-child {
          margin-left: 5px;
        }
      }
      button {
        padding: 6px 20px;
        margin-left: 6px;
        background-color: transparent;
        color: $grey;
        border: 1px solid $grey !important;
        font-weight: 500;
        &:hover,
        &:focus {
          background-color: $grey !important;
        }
      }
    }
  }
  &.BuyerDetails {
    ul {
      li {
        span {
          &:first-child {
            width: 70px !important;
          }
        }
      }
    }
  }
  &.OrderDetails {
    ul {
      li {
        span {
          &:first-child {
            width: 130px !important;
          }
        }
      }
    }
  }
}
.booking_card {
  padding: 10px;
  margin: 0;
}
.booking_wrapper {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 30px;
}

.order_item {
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: none;
  margin-bottom: 0px;
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date {
      color: $lightGray;
      font-size: 13px;
      margin-top: 6px;
    }
    i {
      margin-left: 10px;
      color: $primary;
      font-size: 22px;
      cursor: pointer;
      transform: scale(1);
      transition: 0.2s ease-in-out;
      &.popupIcon {
        transform: scale(1.5);
      }
    }
  }
  .card-body {
    padding: 15px;
    .packageName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-title {
        margin: 10px 0px 0;
        font-size: 18px;
        color: $secondary;
        font-weight: 600;
        color: $grey;
        span {
          display: block;
          font-size: 14px;
          color: $lightGray;
          font-weight: 400;
          margin-top: 8px;
        }
      }
      .price {
        color: $grey;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .tourName {
    margin-top: 15px;
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 6px;
    }
    p {
      color: $lightGray;
      margin-bottom: 15px;
    }
    ul {
      margin: 0 0 10px;
      display: flex;
      padding: 0;
      li {
        padding-right: 15px;
        span {
          margin-right: 5px;
          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
}
.orderStatus {
  .price {
    font-size: 16px;
    font-weight: 500;
  }
  .order {
    padding: 4px 16px;
    border-radius: 50px;
    font-size: 14px;
    &.placed {
      background-color: #5bd19f33;
      color: #31b27c;
    }
    &.pending {
      background-color: #fdb92733;
      color: #fdb927;
    }
    &.Cancelled {
      background: #ff424133;
      color: #ff4241 !important;
    }
  }
}
.order_wrapper {
  max-width: 100%;
}
.orderId {
  display: flex;
  span {
    display: -webkit-box;
    max-width: 130px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-weight: 600;
  }
}

.submit_container {
  button {
    margin: 0 10px;
    padding: 8px 30px;
    font-weight: 500;
    &.btn-cancel {
      background-color: transparent;
      color: $grey;
      border-color: $grey;
      &:hover {
        background-color: $grey;
        color: $white;
      }
    }
  }
}
.ql-toolbar.ql-snow {
  background-color: #f5f5f5;
  border-color: transparent;
}

// user list
.user_box {
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  border: 1px solid #dfdfdf;
  .user_image {
    height: 280px;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      &[src$=".svg"] {
        padding: 20px;
        object-fit: contain;
      }
    }
    .detele {
      position: absolute;
      top: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 10px;
      font-size: 18px;
      color: $primary;
      cursor: pointer;
    }
    .switch_box {
      position: absolute;
      top: 25px;
      right: 25px;
    }
    .switch_box .custom-control.custom-switch {
      height: 18px;
      min-width: 40px;
    }
    .switch_box .custom-switch .custom-control-label::before {
      height: 18px !important;
      width: 36px !important;
    }
    .switch_box .custom-switch .custom-control-label::after {
      top: 0px;
      left: 5px;
      width: 14px !important;
      height: 14px !important;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      top: -7px;
      left: 3px;
    }
    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      transform: translateX(16px);
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #2eb555;
    }
  }
  .user_content {
    padding: 25px 15px;
    text-align: center;
    h1 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 10px;
      color: $secondary;
      span {
        margin-left: 6px;
        i {
          font-size: 16px;
          margin-right: 4px;
          cursor: pointer;
        }
      }
      .detele {
        color: $error !important;
      }
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      margin: 0;
      margin-top: 6px;
      list-style: none;
      li {
        font-size: 16px;
        padding: 5px 0 0;
        color: #919191;
        font-weight: 300;
        word-break: break-all;
        &:last-child {
          border-color: transparent;
          padding-bottom: 0;
        }
        img {
          width: 20px;
        }
        i,
        img {
          font-size: 14px;
          margin-right: 5px;
          margin-top: 5px;
        }

        i.fa-map-marker {
          font-size: 16px;
          margin-right: 2px;

          + div {
            display: inline;
          }
        }
      }
    }
  }
}
.userList {
  .row {
    margin: 0 -10px;
  }
  [class^="col-"] {
    padding: 0 10px;
    margin-top: 20px;
  }
}

h1.role_permission {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: $secondary;
}

p.copyright.text-center {
  margin-bottom: 0;
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.18em;
}

// form
.innerForm {
  // [class*="col-"] {
  //   padding: 30px 30px 0 !important;
  // }
  // [class*="col-"]:first-child {
  //   border-right: 1px solid #dbdbdb;
  // }
  .form-group {
    .input-group {
      background-color: transparent;
      border-bottom: 1px solid rgba($lightGray, 0.3);
      padding: 0;
      border-radius: 0;
      .form-control {
        background-color: transparent !important;
      }
      .input-group-text {
        background-color: transparent !important;
        padding: 0 !important;
        border-radius: 0;
        border: none;

        i {
          font-size: 16px;
        }
        span {
          &.text_ {
            color: $lightGray;
            font-size: 16px;
            opacity: 0.8;
          }
        }
      }
      textarea {
        height: 120px;
        resize: none;
      }
      &.textarea {
        align-items: flex-start;
        .input-group-text {
          position: relative;
          top: 10px;
        }
      }
    }
  }

  i {
    color: #cac7c7 !important;
    font-size: 16px;
  }
}

.mediaAudioWrap,
.mediaPhotoWrap,
.mediaVideoWrap {
  width: 100%;
  max-width: 262px;
  height: 100%;
  max-height: 272px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
  .mediaPhotoWrap-Feed .groupMediaWrap,
  .groupMediaWrap {
    width: 130px;
    height: 135px;
    cursor: pointer;
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mediaPhotoWrap-Feed img,
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.mediaPhotoWrap-Feed .groupMediaWrap:nth-child(2),
.mediaPhotoWrap .groupMediaWrap:nth-child(2) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}
.mediaPhotoWrap-Feed .groupMediaWrap:nth-child(3),
.mediaPhotoWrap .groupMediaWrap:nth-child(3) {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.mediaPhotoWrap-Feed .groupMediaWrap:nth-child(4),
.mediaPhotoWrap .groupMediaWrap:nth-child(4) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.seeMoreMedia {
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 135px;
  border-radius: 14px;
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  .btn {
    color: #fff !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.98) !important;
  z-index: 9999 !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
  width: 94%;
  padding: 0 !important;
  border: 0 !important;
  height: 98%;
  top: 50% !important;
  border-radius: 12px !important;
  background: #f7f7f7;
  background: transparent !important;
  .closeModalFeed {
    font-size: 16px !important;
    border-radius: 50%;
    padding: 0;
    height: 22px;
    width: 22px;
    line-height: 22px;
    position: absolute;
    top: 12px;
    right: 3px;
    z-index: 999;
    color: #ff2323 !important;
    outline: 0 !important;
    background-color: #fff !important;
    border: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.22) !important;
  }
  .mediaCountCarousel {
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #fff;
    background: #333;
    position: absolute;
    top: 12px;
    left: 4px;
    border-radius: 10px;
    padding: 0 10px;
  }
}
.multipleMediaModal {
  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }
  .carousel-indicators {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: unset;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    background: #333;
    border-radius: 6px;
    padding: 4px;
    max-width: 100%;
    bottom: 5px;
    li {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #dcdcdc;
      &.active {
        background: linear-gradient($primary, $secondary);
      }
    }
  }
}
.ReactModal__Content.ReactModal__Content--after-open .mediaPhoto-Feed {
  object-fit: contain;
}
.mediaPhoto-Feed {
  border-radius: 12px;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.01);
}

.multipleMediaModal .carousel-control-next,
.multipleMediaModal .carousel-control-prev {
  opacity: 1;
  width: 42px;
  top: 45%;
  height: 10%;
}
.multipleMediaModal .carousel-control-prev {
  left: -2%;
}
.multipleMediaModal .carousel-control-next {
  right: -2%;
}
.multipleMediaModal .carousel-control-next-icon {
  padding-left: 2px;
}
.multipleMediaModal .carousel-control-next-icon,
.multipleMediaModal .carousel-control-prev-icon {
  height: 24px;
  width: 24px;
  background-color: hsla(0, 0%, 100%, 0.4);
  // box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  border-radius: 50%;
  line-height: 25px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
  cursor: pointer;
  height: 34px;
}

.carousel-control-next-icon:before,
.carousel-control-prev-icon:before {
  content: "\f054";
  font-family: FontAwesome;
  color: #fd3762;
  font-size: 24px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.multipleMediaModal .carousel-control-next-icon:before,
.multipleMediaModal .carousel-control-prev-icon:before {
  font-size: 14px;
  color: #ccc;
}
.carousel-control-prev-icon {
  background-image: none;
}
.carousel-control-prev-icon:before {
  content: "\f053";
}

// body
body {
  &.sidebar-mini {
    .navbar-minimize {
      button {
        .menudir {
          transform: rotate(0);
        }
      }
    }
    .sidebar {
      .logo {
        img {
          &.logo-mini {
            display: block !important;
          }
          &.logo-main {
            display: none;
          }
        }
      }
      .nav {
        .nav-item {
          .nav-link {
            margin: 8px 10px;
            padding: 8px 15px;
            i {
              margin: 0;
            }
            p {
              display: none;
            }
          }
          &.active {
            .nav-link {
              background: transparent !important;
              i {
                color: $primary !important;
              }
            }
          }
        }
      }
      &:hover {
        .logo {
          img {
            &.logo-mini {
              display: none !important;
            }
            &.logo-main {
              display: block !important;
            }
          }
        }
        .nav {
          .nav-item {
            .nav-link {
              margin: 10px 25px;
              i {
                margin-right: 15px;
              }
              p {
                display: block;
              }
            }
            &.active {
              .nav-link {
                background: linear-gradient(
                  to right,
                  $primary,
                  $secondary
                ) !important;
                i {
                  color: $white !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.group_image {
  background-color: white;
  width: 46px;
  height: 46px;
  border: 1px dashed rgba(130, 130, 130, 0.4) !important;
  border-radius: 6px !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  i.fa-close {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
    font-size: 12px;
    background-color: #fff;
    color: #f86c6b !important;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    &:hover {
      background-color: #fd3762;
      color: #fff !important;
    }
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px !important;
  }
  &.plus {
    img {
      width: 30px;
      cursor: pointer;
    }
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 50% !important;
      height: 50%;
    }
  }
}

.amenity-location {
  .replace_map {
    color: $primary;
    font-size: 14px;
    display: block;
    width: 70px;
    text-align: center;
    cursor: pointer;
  }
}

.chatEmoji {
  position: absolute;
  left: 0;
  bottom: 130px;
  left: 20px;
}

.chatSpinner {
  margin: auto !important;
}
.vayco-admin {
  .wedding-group-chat.requests {
    .all-members-chat-body {
      table {
        tbody {
          tr {
            background-color: transparent !important;
            td {
              padding: 10px 0 !important;
              .chat-profile {
                .media-body {
                  .media-heading {
                    font-size: 14px !important;
                    color: $black;
                  }
                }
              }
              span.service {
                color: $black !important;
                font-size: 16px !important;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

.user_info > a img {
  width: 40px;
  height: 40px;
}

.user_info {
  .dropdown-menu {
    a {
      padding: 8px 10px;
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
  }
}
.profile_center {
  .group_image {
    width: 100px;
    height: 100px;
  }

  .replace_map {
    width: 100px;
    text-align: center;
  }
}

.notificationContentWrap {
  border: 1px solid $border-light;
  padding: 15px !important;
  border-radius: 10px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0 5px 10px 0 rgba($color: #000000, $alpha: 0.1);
  }
  .notificationDay {
    font-weight: 500;
    color: $lightGray;
  }
  ul {
    padding: 0;
    margin-top: 14px;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        span {
          display: block;
          font-size: 14px;
          color: $lightGray;
          margin-top: 3px;
        }
      }
      p.Notification_content {
        font-size: 15px;
        margin-bottom: 0;
        color: rgba($black, 0.8);
        .commentTime {
          position: relative;
          padding-left: 20px;
          font-size: 14px;
          font-weight: 400;
          &::before {
            content: "";
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translate(0, -50%);
            border: 2px solid rgba($lightGray, 0.6);
            border-radius: 50%;
          }
        }
      }

      + li {
        margin-top: 20px;
      }
    }
  }
}

.offerModal {
  .innerForm .form-group .input-group textarea {
    height: 80px;
  }
}
.rdtPicker {
  border: none;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  &:after,
  &:before {
    display: inline-block;
    position: absolute;
    content: "";
    top: -5px;
    left: 10px;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }
}
.rdtPicker .dow,
.rdtPicker .rdtTimeToggle,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker th.rdtSwitch {
  color: #51cbce;
}
.rdtPicker .dow,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker th.rdtSwitch {
  color: #2c4199 !important;
}
.rdtPicker .rdtDay.rdtActive,
.rdtPicker .rdtDay.rdtActive:hover,
.rdtPicker .rdtDay.rdtToday.rdtActive {
  background-color: #2c4199 !important;
}
.rdtDays tr .rdtNew,
.rdtDays tr .rdtOld {
  color: #ddd;
}
.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #2c4199;
  border: 1px solid #2c4199;
}

span.readMoreText {
  color: #e45060 !important;
}
.leftcount {
  width: 100%;
  text-align: right;
  font-size: 14px;
  display: block;
  margin-top: 4px;
  color: rgba($lightGray, 0.7);
}

.switch_with_content .custom-switch.custom-control {
  padding-left: 3.25rem;
  margin: 0;
}

.switch_with_content .custom-switch .custom-control-label::before {
  left: -3.25rem;
  width: 2.75rem !important;
  height: 20px !important;
  pointer-events: all;
  border-radius: 0.5rem;
  top: 12px;
}

.switch_with_content .custom-switch .custom-control-label::after {
  left: -3.25rem;
  top: 12px;
  width: 18px !important;
  height: 18px !important;
}

.switch_with_content
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(26px) !important;
  top: 3px !important;
}

// kpi dashboard
.cardGraph {
  .card-header {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .card-body {
    padding: 0 !important;
  }
  .apexcharts-toolbar {
    top: -24px !important;
    z-index: 1;
    .apexcharts-menu-icon {
      transform: scale(1) !important;
    }
  }
}

.filterDashboard {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px 0;

  label {
    margin-right: 10px !important;
    margin-bottom: 0 !important;
    font-weight: 500;
    color: $secondary;
  }

  .DateInput_input {
    font-size: 14px;
    height: 100% !important;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
  }

  .btn-clear {
    padding: 12px 16px !important;
  }

  .DateRangePicker_1 {
    z-index: 9;
  }
}

.custom-control {
  min-height: inherit !important;
}

@import "./responsive";

//login
.authWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .leftSide {
    flex: 0 0 50%;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-image: url("../img/UH-60.jpg");
    background-position: center;
    background-size: cover;
    position: relative;

    .contentWrapper {
      width: 80%;
      position: absolute;
      left: 50px;
      bottom: 100px;
      margin: auto;

      img {
        width: 120px;
        margin-bottom: 30px;
      }

      h2 {
        font-weight: 600;
        color: $white;
        line-height: 40px;
        font-size: 30px;

        &::after {
          content: "";
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 150px;
          height: 2px;
          background-color: #d4ddee;
        }

        &::before {
          content: "";
          position: absolute;
          top: auto;
          bottom: -20px;
          left: 0;
          width: 50px;
          height: 2px;
          background-color: $white;
          z-index: 1;
        }
      }
    }
  }

  .rightSide {
    flex: 1 1;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loginWrapper {
      max-width: 70%;
      width: 100%;
      height: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .projectLogo {
        cursor: pointer;
        display: block;
        margin: 20px auto;
        height: 140px;
      }

      h4 {
        text-align: center;
        font-weight: 600;
        font-size: 26px;
        color: $theme-color;
        margin: 20px 0;
      }

      form {
        width: 100%;
      }
    }

    .forgotPassword {
      color: $theme-color;
      text-align: right;
      flex: 1 1;
      cursor: pointer;
      margin-top: 6px;
    }
  }
}
