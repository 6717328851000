.vayco-auth {
  .vayco-public {
    .public-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px !important;
      border: 0 !important;
      z-index: 5;
    }
    &.login_singup {
      .login_wrapepr {
        flex: 1;
        > div {
          align-items: center;
          width: 400px;
          .btn {
            width: 100%;
          }
        }
      }
      .public-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #fff;

        .public-logo {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex: 1;
          img {
            height: 70px;
            width: auto;
            object-fit: contain;
          }
          span {
            color: rgba($black, 0.9) !important;
            margin-left: 5px !important;
          }
        }
        .loginLink {
          color: $secondary;
          font-weight: 600;
          text-decoration: underline;
          padding-right: 0;
        }
        &::before {
          // content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to right, #c8c8c8, #f0f0f0);
          // -webkit-clip-path: polygon(78% 0, 82% 100%, 0 100%, 0 0);
          // clip-path: polygon(89% 0, 92% 100%, 0 100%, 0 0);
          z-index: -1;
        }
      }
    }

    .loginPgContent {
      max-width: 650px;
      margin: 0 auto;
      padding: 25px 0 35px;
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      box-shadow: none !important;

      .card-group {
        padding: 5px 5px 2px !important;
      }
    }

    .public-title {
      letter-spacing: 0.5px;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 15px;
      color: #1e295c;
    }

    .back-login {
      background-color: transparent !important;
      color: #333 !important;
      border: none !important;
      box-shadow: none !important;
      margin: 0 auto;
    }

    .back-login i {
      color: #333 !important;
    }

    .card {
      border: none !important;
      .card-header {
        background-color: transparent;
        padding: 15px;
        .company-logo {
          margin: 0 !important;
          width: 140px;
        }

        a {
          color: #23282c !important;
        }
      }
    }

    .country-code {
      display: flex;
      align-items: center;
      padding: 0 !important;
      label {
        margin-bottom: 0;
        select {
          width: auto;
          background-color: #f0f3f5 !important;
        }
      }
    }

    .login-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      width: 100%;
      margin: 15px auto 10px;
    }

    .login-footer * {
      letter-spacing: 0.3px;
      font-size: 14px;
    }
  }

  .footer.public {
    // display: flex;
    // align-items: center;
    margin-top: 40px;
    height: auto !important;
    padding: 15px 20px 10px !important;
    background-color: white !important;

    .left {
      flex: 1 1;
      max-width: 100%;
    }
    .powered-by {
      text-align: right;
      display: block;
    }
    span {
      font-size: 16px;
    }
  }
}

.back-to-home {
  font-size: 16px;
}

.table tbody tr td .tableImage {
  width: 25px !important;
  height: 25px !important;
}

.submitWrapper {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

// ====== Vayco Admin =======

.vayco-admin {
  .fa-filter {
    font-size: 20px;
    color: $primary;
    width: 20px !important;
    margin-right: 20px;
    margin-bottom: 15px;
  }

  .app {
    padding: 20px 5px;
  }
  // ===== Search By Name =====
  .search-by-name {
    position: relative;

    .form-control {
      padding-right: 60px;
    }
    .fa-search {
      position: absolute;
      top: 47px;
      right: 20px;
      font-size: 14px;
    }
  }

  // ------ Left --------
  .search-by-name.pos-left {
    .form-control {
      padding-left: 50px;
      padding-right: 20px;
    }
    .fa-search {
      top: 53px;
      right: unset;
      left: 20px;
    }
  }

  select {
    color: #333;
  }

  // ==== Home Amenity =====
  .amenity-container {
    .card {
      img {
        width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }
  }

  // ===== Add Amenity =======
  .coverPhoto {
    width: 65px;
    height: 65px;
    border: 1px dashed #ddd;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    i {
      font-size: 28px;
      width: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 2px;
      border-radius: 50px;
    }
  }

  .cover_content {
    flex: 1;
    margin-left: 10px;
    h1 {
      font-size: 16px;
      font-weight: 600;
      color: $primary;
      margin: 0 0 8px;
    }
    p {
      font-size: 12px;
      color: #b5b5b5;
      margin: 0;
    }
  }
  .input-gallery {
    .input-gallery-col {
      width: 80px;
      height: 80px;
      margin-right: 25px;
      margin-bottom: 35px;
      &:last-child {
        margin-right: 0;
      }

      .input-gallery-img {
        width: 100%;
        height: 100%;

        margin-bottom: 6px;
        cursor: pointer;
        border-radius: 6px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }
        i.fa-close {
          position: absolute;
          top: -10px;
          right: -10px;
          z-index: 1;
          font-size: 12px;
          background-color: $white;
          color: $error !important;
          border-radius: 50px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px -2px 10px 0px rgba($color: #000000, $alpha: 0.1);
          &:hover {
            background-color: $primary;
            color: $white !important;
          }
        }
        label {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: -1;
          }
          i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 24px;
            color: #626060;
          }
        }
      }
      span {
        color: $primary;
        display: block;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        text-transform: capitalize;
        &:hover {
          text-decoration: underline;
        }
      }
      &.plus {
        .input-gallery-img {
          border: 1px dashed rgba($lightGray, 0.4) !important;
          label {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 20px;
            height: 20px;
          }
          i {
            font-weight: 600;
          }
        }
      }
    }
  }

  .input-gallery-col.selected {
    cursor: default;
    i {
      display: none !important;
    }
    span {
      color: $lightGray;
      opacity: 0.8;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .amenity-location {
    span:not(.replace_map) {
      color: #919191;
      img {
        margin-right: 10px;
      }
    }
    .input-gallery {
      .input-gallery-col {
        margin-right: 10px;
        margin-bottom: 0;
        width: 60px;
        height: 60px;
        &.largesize {
          width: 100px;
          height: 100px;
          margin: auto;
          margin-top: 8px;
        }

        .input-gallery-img {
          margin-bottom: 0;
          i {
            font-size: 16px;
            color: rgba($primary, 0.6) !important;
            &.fa-close {
              font-size: 10px !important;
            }
          }
        }
        span.replace_map {
          label {
            margin: 6px 0;
            width: 60px;
            color: $primary;
            font-size: 12px;
            cursor: pointer;
            input {
              display: none;
            }
          }
        }
      }
    }
  }

  h2.title_ {
    margin: 0;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    color: $secondary;
  }

  // === Availability ===
  .availability {
    .card {
      padding: 0;
      margin-bottom: 0;
      .timing {
        display: flex;
        flex-wrap: wrap;
        // .custom-radio.custom-control {
        //   margin: 0 15px 15px 0;
        //   padding: 0 !important;
        // }
        // label.custom-control-label {
        //   background: #f2f2f2;
        //   padding: 10px;
        //   border-radius: 10px;
        //   border: 1px solid transparent;
        // }

        // .custom-control-input:checked ~ .custom-control-label {
        //   border-color: $primary;
        //   background: #fff9fa;
        // }
        // .custom-control-label::before,
        // .custom-control-label::after {
        //   display: none;
        // }
        .form-group {
          margin-right: 10px;
        }
        .btn-delete {
          width: auto;
          padding: 0;
          i {
            color: $error !important;
          }
        }

        .add_timing {
          width: auto;
          padding: 6px 6px 20px;
          i {
            color: $primary !important;
            font-size: 16px;
          }
        }
      }
    }
    button {
      .badge {
        margin-left: 6px;
        margin-right: 6px;
        background-color: transparent;
        color: $black;
      }
      .form-error {
        font-weight: 500;
      }
    }

    .collapse_Container {
      border-bottom: 1px solid #dbdbdb !important;
      button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        font-weight: 600;
        background-color: transparent !important;
        margin-bottom: 0px !important;
        i {
          font-size: 14px;
        }
      }
    }

    .availability-row {
      > .row:not(:last-child) {
        margin-bottom: 20px;
      }
      .custom-control {
        margin-top: 10px;
      }
      .chip-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        button {
          display: flex;
          align-items: center;
          border-radius: 30px;
          line-height: 1;
          font-size: 14px;
          padding: 10px;
          margin: 0 8px 15px !important;
          i {
            margin-right: 8px;
            color: #43a756 !important;
          }
          &:hover {
            i {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  .pos-manage-admin {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 14px;
      color: #dc3545;
    }
    .custom-switch {
      display: inline-block !important;
      min-width: 40px;
      height: 15px;
      min-height: auto !important;
      label {
        transform: scale(0.5);
      }
    }
  }

  // Roles & Permissions
  .roles-perm {
    .form-group .custom-control {
      margin: 10px 0px 40px;
    }

    .custom-control-label {
      &::before,
      &::after {
        left: -35px !important;
      }
    }
  }

  // Feed
  .wedding-group-chat {
    // =============
    .capsule-container {
      .capsule-btn {
        cursor: default;
      }
    }
    .tabsCustom {
      .capsule-container {
        > .capsule-btn {
          cursor: pointer;
          white-space: nowrap;
        }
      }
    }

    .btn-primary {
      background: $primary;
      border: none;
      border-radius: 10px;
      font-weight: 800;
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 5px 10px 0 rgba($primary, 0.2) !important;
        background-color: $primary;
      }
      &-outline {
        background: white !important;
        color: $primary !important;
        border-radius: 10px;
        border-color: $primary;
        overflow: hidden;
      }
    }

    .card {
      margin-bottom: 0;
    }

    // ==== Nav Tabs =====
    .nav-tabs {
      border: none !important;
      justify-content: center;
      .nav-link {
        font-weight: bold !important;
        &.active,
        .btn:focus {
          .btn-primary-outline {
            background: $primary !important;
            border-color: $primary;
            color: white !important;
          }
        }
      }
    }

    .tab-pane {
      padding: 0 !important;
    }

    // ==== Chat Left ====
    .all-members-chat-container {
      .all-members-chat-row {
        border-radius: 36px;
        img {
          transition: all 0.3s linear;
        }
        &:hover img {
          transform: scale(1.3);
        }
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    // === Chat Right ===
    .chat-card {
      .card-header {
        border-bottom: 1px solid $border-light !important;
        color: $secondary;
        padding: 10px !important;
      }

      .card > .card-body {
        height: 450px;
        overflow-y: auto;
      }

      .chat-input-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > * {
          height: 60px;
        }

        .emoji-text {
          display: flex;
          align-items: center;
          background-color: #f2f2f2;
          border-radius: 25px;
          flex: 1 1 auto;
          margin-right: 10px;
        }

        .emoji,
        .send-button button i {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .send-button {
          width: 10%;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            border-radius: 100% !important;
            overflow: hidden;
            display: flex;
            width: 60px;
            align-items: center;
            justify-content: center;
          }
          i {
            font-size: 17px;
            color: white;
            background-color: $primary;
          }
        }

        .input-box {
          position: relative;
          .custom-attachment {
            input {
              position: absolute;
              opacity: 0;
              pointer-events: nonef;
              display: none;
              user-select: none;
            }
            position: absolute;
            top: 50%;
            right: 20px;
            width: 30px;
            height: 30px;
            margin-bottom: 0 !important;
            cursor: pointer;
            transform: translate(0, -50%);
            i {
              color: #bbbbbb;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }
          input,
          textarea {
            width: 100%;
            height: 100%;
            outline: none;
            line-height: 1;
            resize: none;
            background-color: transparent !important;
            padding: 15px 60px 15px 15px;
            border: none;
          }

          textarea {
            line-height: 1.3;
            margin-top: 6px;
          }
        }
      }
      .editCancel {
        background-color: transparent !important;
        color: $primary;
        padding-left: 0;
      }
    }

    // ====== Requests Page =======
    &.requests {
      .chat-card .card-header {
        padding: 8px 15px !important;
      }

      // ====== Request Strip Details ======
      .chat-top-strip {
        display: flex;
        justify-content: center;
        border: 1px solid $border-light;
        border-radius: 7px;
        padding: 10px 14px;
        margin: 15px;
        ul {
          display: flex;
          align-items: center;

          flex-wrap: wrap;
          width: 100% !important;
          margin-bottom: 0 !important;
          padding: 0 !important;
          li {
            font-size: 16px;
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-right: 10px;
            }

            b {
              margin-left: 5px;
              color: $secondary;
            }

            b {
              font-weight: 400 !important;
            }

            span {
              color: $lightGray !important;
            }

            &.gp {
              b {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }

      // ====== Request Card Details ========
      .request-card-details {
        background-color: $secondary;
        box-shadow: 0px 3px 5px -3px $lightGray !important;
        // margin-top: 60px;
        padding: 15px 25px;
        .addUserModal {
          max-width: 350px !important;
        }

        b {
          color: white !important;
          font-weight: 500 !important;
        }

        span {
          color: #b7b7b7 !important;
        }

        b,
        span {
          font-size: 14px;
        }

        &.white-wrap {
          b,
          span {
            white-space: nowrap;
          }
        }

        .fa-pencil-alt {
          margin-right: -10px;
        }
        form {
          margin-top: 10px;
          input,
          textarea {
            height: 40px !important;
          }
          .form-group {
            margin-bottom: 0 !important;
          }
        }
        ul {
          li {
            padding-right: 10px !important;
          }
        }
      }

      table {
        thead {
          tr {
            background-color: $white;
          }
        }
        tbody {
          tr {
            background-color: rgba(242, 245, 245, 0.8) !important;
            td {
              select {
                background-color: transparent;
                border-color: rgba($lightGray, 0.2);
                padding: 8px 10px;
                height: auto;
              }
            }
            &.unread-request {
              background-color: $white !important;
              td {
                color: $black !important;
              }
            }
          }
        }
      }
    }
  }
}

// ======= Public Protected =======
.public-protected {
  .main-panel {
    width: 100% !important;
    min-height: 100vh;
    float: none !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

// ==== Create New Group Modal ======
.create-group {
  .modal-header {
    padding: 40px 30px 0 !important;
  }

  .modal-body {
    padding: 10px 40px !important;
  }

  .modal-content {
    padding: 0 !important;
  }

  .modal-footer {
    text-align: center;
    display: block;
    font-size: 15px;
    color: #919191 !important;
    padding: 15px 0 !important;
    background-color: #f7f7f7 !important;
  }

  .custom-file {
    height: 100%;
    .custom-file-input {
      height: 0 !important;
      position: absolute !important;
    }
    .custom-file-label {
      height: 100% !important;
      position: static !important;
      border: none !important;
      padding: 0 !important;
      &::after {
        display: none !important;
      }

      .custom-file-container {
        display: flex;
        align-items: center;
        margin-top: 30px;
        .imgbox {
          width: 60px;
          height: 60px;
          border: 1px dashed #989898;
          border-radius: 100%;
          padding: 15px;
          margin-right: 25px !important;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          margin-right: 15px;
        }
      }
    }
  }
}

// ======= QR Code Modal ========
.qrModal {
  .qr-logo {
    width: 70px;
  }

  .qr-code {
    border: 1px solid #b6b2b2;
    padding: 10px;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
    }
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .modal-body {
    padding-top: 10px !important;
  }

  .modal-footer {
    button {
      min-width: 200px;
      i {
        font-size: 14px;
      }
    }
  }

  .poweredBy {
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
    p {
      margin-bottom: 5px;
    }
    img {
      width: 70px;
    }
  }
}

// ======= View All Group Modal ========
.viewAllGroupModal {
  .modal-header .modal-title {
    text-align: left !important;
  }
}

// ======= Add New User Modal =========
.addUserModal {
  .user-img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 20px #403e3e29;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ul {
    margin-top: 30px;
    li {
      margin-bottom: 10px;
    }
  }
}

// datetime picker
.react-datepicker {
  width: 100%;
  border: none !important;
  margin-top: 20px;
  font-family: "Poppins", sans-serif !important;
  .react-datepicker__navigation-icon::before {
    border-color: #737373;
  }
  .react-datepicker__navigation {
    height: 20px;
    align-items: unset;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .react-datepicker__month-container {
    width: 100%;
    .react-datepicker__header {
      background: transparent;
      border: none !important;
      padding: 0;
      .react-datepicker__day-name {
        width: 50px;
        color: #929292;
        font-size: 14px;
      }
      .react-datepicker__day-names {
        border: 1px solid #e3e3e3;
        border-bottom: 0;
        margin: 0;
        margin-top: 20px;
        padding: 10px 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      .react-datepicker__current-month {
        color: $darkblue;
        font-weight: 600;
        font-size: 16px !important;
      }
    }
    .react-datepicker__month {
      padding: 0 !important;
      margin: 0;
      border: 1px solid #e3e3e3;
      border-top: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      .react-datepicker__week {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .react-datepicker__day {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50% !important;
        font-weight: 500;
        color: #929292;
        font-size: 14px !important;
        &:hover,
        &:focus {
          color: #07294c !important;
          background-color: transparent !important;
        }
        &.react-datepicker__day--selected,
        &.react-datepicker__day--keyboard-selected {
          font-weight: 600;
          color: #07294c !important;
          background-color: transparent !important;
        }
        &.react-datepicker__day--today {
          background: linear-gradient(
            to right,
            $primary,
            $secondary
          ) !important;
          color: $white !important;
        }
        &.react-datepicker__day--disabled {
          cursor: not-allowed !important;
        }
      }
    }
  }
}

.fbSignUpBtn,
.googleSignUpBtn {
  border: 1px solid #b6b6b6 !important;
  height: 46px;
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
  color: $grey !important;
  background: $white !important;

  margin: 0 auto 15px;
  line-height: 44px;
  // @include ripple;
  font-size: 14px;

  img {
    height: 24px;
    vertical-align: -7px;
    margin-right: 10px;
  }
}

// datepicker
.request_service.modal-dialog {
  max-width: 500px !important;
}
.timerpicker {
  width: 100px !important;
  .react-datepicker-wrapper {
    margin-left: 10px;
    input {
      border: 1px solid #cfcfcf !important;
      padding: 4px;
      text-align: center;
      color: #929292;
      width: 100px !important;
      outline: none !important;
    }
  }
}

.totalGuest.form-group {
  label {
    font-weight: 600 !important;
    color: $secondary !important;
    font-size: 16px !important;
    margin-bottom: 0;
  }
  .counting {
    width: 100px;
    border: 1px solid $primary;
    border-radius: 8px;
    button {
      padding: 5px;
      font-size: 14px;
      background-color: transparent;
      color: $primary;
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        background-color: transparent !important;
        color: $primary;
      }
    }
    input {
      height: 30px;
      text-align: center;
      padding: 0;
      background-color: transparent !important;
      border: none !important;
      font-weight: 600;
      color: $secondary;
      font-size: 18px;
    }
  }
}

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 15px;
  z-index: 999;
  height: 50px;
  background-color: $primary;
  padding: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  #uploadeProgressHeader {
    color: $white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}

.loaderWrap {
  width: 300px;
  height: 250px;
  text-align: center;

  p {
    font-size: 16px;
    margin-top: 10px;
    color: $grey;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

// toast
.toast {
  background-color: "white" !important;
  border-color: "#1e295c" !important;
  // color: '#1e295c' !important;
  color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  padding: 5px 12px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  letter-spacing: 0.3px;
  flex-basis: 0 !important;
}

.spinnerLogin {
  position: absolute;
  top: 11px;
  right: 12px;
  color: $grey;
  font-size: 16px;

  .fa {
    cursor: pointer;
  }
}

.spinnerLogin {
  top: 8px;
}

.cursorPointer {
  cursor: pointer;
}

.sweet-alert {
  h2 {
    font-size: 26px !important;
    span {
      color: $primary;
      margin: 0 5px;
    }
  }
}

// === Profile Page ===
.profile .replace_map {
  color: $primary !important;
}

// ==== Blogs Page ===

.blogs-card {
  .blogs-img {
    width: 60%;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 40px;
    box-shadow: 0px 4px 6px 0 #00000026;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .blogs-title {
    font-size: 24px;
    font-weight: 800;
    color: #2f8390;
    line-height: 1.5;
    margin-bottom: 18px;
  }

  p,
  span,
  label {
    color: #707070;
    font-size: 18px;
  }

  .form-control {
    height: auto;
    font-size: 22px;
    padding-left: 0;
    border: none !important;
    border-bottom: 1px solid #22242478 !important;
    background: transparent !important;
  }
}

// ====== User Detials Page
.user-detials-page {
  .user_box {
    background-color: white;
    .user_image {
      width: 110px !important;
      height: 110px !important;
      border: 1px solid #e0e0e0;
      overflow: hidden;
      padding: 4px;
      border-radius: 100%;
      margin: 15px auto;

      img {
        border-radius: 100%;
        padding: 0 !important;
        object-fit: cover !important;
      }
    }

    .user_content {
      text-align: left;
      ul {
        align-items: flex-start;
        li {
          strong {
            font-weight: 700;
            color: rgb(139, 138, 138);
          }
        }
      }
    }
  }

  // ====== Tabs =======
  .nav-tabs {
    .nav-link {
      padding: 0 15px;
      border: none;
      cursor: pointer;
      font-size: 15px;
      color: $grey;
      background-color: transparent;
      &.active {
        color: $primary;
        border-bottom: 1px solid $primary;
      }
    }
  }

  .tab-pane {
    padding: 0;
  }
}
